import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { App } from './Wallet';
import getDiscordAC from './DiscordVerification';
import { EasybaseProvider } from 'easybase-react';
import ebconfig from './ebconfig';
import imageFile from './servers/bbs.png';
import nullImage from './null.jpg';
import { Redirect } from "react-router-dom";

// Use require instead of import, and order matters
require('@solana/wallet-adapter-react-ui/styles.css');
require('./index.css');

export let discordid = '';

const runDiscordAC = async() => {
     await getDiscordAC()
    .then( response => {
	if (response == 'callback') {
        } else if (response != '') {
		discordid = response.id;
		let fullimage;
		if (response.avatar == null) {
			fullimage = nullImage;		
		} else { 
			fullimage = `https://cdn.discordapp.com/avatars/${response.id}/${response.avatar}?size=64`;		
		}
		ReactDOM.render(
		    <StrictMode>
			 <EasybaseProvider ebconfig={ebconfig}>
			    <div id='walletimages'><img className='walletimage' width='64' height='64' src={imageFile}></img> &lt;=&gt; <img className='walletimage' width='64' height='64' src={fullimage}></img></div>
			    <div id='walletname'>{response.username}#{response.discriminator}</div>
			    <div id='wallettitle'>Verification for Bold Badgers</div>
			 <App />
			 </EasybaseProvider>
		    </StrictMode>,
		    document.getElementById('root'),
		);
	} else {
		discordid = response.id;
		ReactDOM.render(
		    <StrictMode>
			    <div id='wallettitle'>Starting Verification</div>
		    </StrictMode>,
		    document.getElementById('root'),
		);	
	}
    });
};

const backup = console.error;

console.error = function filterWarnings(msg) {
  const supressedWarnings = ['render'];

  if (!supressedWarnings.some(entry => msg.includes(entry))) {
    backup.apply(console, arguments);
  }
};

export default { discordid: discordid } 

runDiscordAC();