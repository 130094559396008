import { useWallet } from '@solana/wallet-adapter-react';
import bs58 from 'bs58';
import React, { FC, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { sign } from 'tweetnacl';
import { useAddUser } from './mainstorage';
import { discordid } from './index';

export const SignMessageButton: FC = () => {
    const { publicKey, signMessage } = useWallet();
    const { addUser } = useAddUser();
    
    const onClick = React.useCallback(
      async () => {
        try {
            if (!publicKey) throw new Error('Wallet not connected!');
            if (!signMessage) throw new Error('Wallet does not support message signing! Please use a wallet such as Phantom or Solflare! NOTE: Some Ledgers wallets are not supported!');

            const message = new TextEncoder().encode('Omega Protocol - Signature verification for Bold Badgers.');
            const signature = await signMessage(message);
            if (!sign.detached.verify(message, signature, publicKey.toBytes())) throw new Error('Invalid signature!');

	    await addUser(publicKey?.toBase58(), discordid, bs58.encode(signature));
	    ReactDOM.render(
		    <div id='center'>Discord has been linked to your wallet!<br />Roles will be added within 5 minutes.<br />You can now close this page.</div>,
	    document.getElementById('walletmain'),
	    );
		
        } catch (error: any) {
            alert(`Signing failed: ${error?.message}`);
        }
    }, [publicKey, signMessage, addUser]);
    return signMessage ? (<button className="wallet-adapter-button wallet-adapter-button-trigger shine" onClick={onClick} disabled={!publicKey}>Verify</button>) : null;       
};
